/* eslint-disable no-console */
import React, { createContext, Context, useMemo, useCallback } from 'react'
import { Job } from 'model/ats'
import { useAts } from 'hooks/useAts'

type AtsContextType = {
  linkedAccount: any
  linkedAccountError: any
  linkedAccountIsLoading: boolean
  linkToken: any
  createLinkedAccount: (variables?: any) => any
  getLinkToken: (variables?: any) => any
  jobs: Job[]
  jobsIsLoading: boolean
  jobsError: boolean
  getJobById: (jobId: string) => void
  job: Job | null
  jobIsLoading: boolean
  jobError: boolean
  isAtsLinked: boolean
  atsLastSynced: string | null
}

export const AtsContext: Context<AtsContextType> =
  createContext<AtsContextType>({
    linkedAccount: undefined,
    linkedAccountError: undefined,
    linkedAccountIsLoading: true,
    linkToken: undefined,
    createLinkedAccount: () => {},
    getLinkToken: () => {},
    jobs: [],
    jobsIsLoading: true,
    jobsError: false,
    getJobById: () => {},
    job: null,
    jobIsLoading: true,
    jobError: false,
    isAtsLinked: false,
    atsLastSynced: null
  })

export const AtsProvider = ({ children }: any): JSX.Element => {
  const {
    jobs,
    jobsIsLoading,
    jobsError,
    getAtsJobById,
    job,
    jobIsLoading,
    jobError,
    linkedAccount,
    linkedAccountError,
    linkedAccountIsLoading,
    linkToken,
    createLinkedAccount,
    getLinkToken,
    isAtsLinked,
    atsLastSynced
  } = useAts()

  const getJobById = useCallback(
    async (jobId: string) => {
      if (jobId) {
        await getAtsJobById({
          variables: { id: jobId }
        })
      }
    },
    [getAtsJobById]
  )

  const value: AtsContextType = useMemo(
    () => ({
      linkedAccount,
      linkedAccountError,
      linkedAccountIsLoading,
      linkToken,
      createLinkedAccount,
      getLinkToken,
      jobs,
      jobsIsLoading,
      jobsError,
      getJobById,
      job,
      jobIsLoading,
      jobError,
      isAtsLinked,
      atsLastSynced
    }),
    [
      linkedAccount,
      linkedAccountError,
      linkedAccountIsLoading,
      linkToken,
      createLinkedAccount,
      getLinkToken,
      jobs,
      jobsIsLoading,
      jobsError,
      getJobById,
      job,
      jobIsLoading,
      jobError,
      isAtsLinked,
      atsLastSynced
    ]
  )

  return <AtsContext.Provider value={value}>{children}</AtsContext.Provider>
}
