export enum EmployerStaffAccountRole {
  BASIC_USER = 'BASIC_USER',
  MANAGER = 'MANAGER',
  SUPER_MANAGER = 'SUPER_MANAGER'
}

export enum EmployerStaffAccountJobsAccessLevel {
  BASIC_JOBS_ACCESS = 'BASIC_JOBS_ACCESS',
  JOBS_LEAD = 'JOBS_LEAD',
  NO_JOBS_ACCESS = 'NO_JOBS_ACCESS'
}

export enum EmployerStaffAccountEhiAccessLevel {
  FULL_EHI_ACCESS = 'FULL_EHI_ACCESS',
  NO_EHI_ACCESS = 'NO_EHI_ACCESS',
  BASIC_EHI_ACCESS = 'BASIC_EHI_ACCESS'
}

export enum EmployerStaffAccountStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING'
}

export enum CompanyAdminAccessCodes {
  MAHITSON_ORG = 'EP::MTSN',
  CHANGEFORCE_ORG = 'EP::CHFR'
}

export type User = {
  id: string
  name: string
  primaryEmail: string
}

export type AuthenticationSettings = {
  domain?: string
  idpType?: IdpType
  strategy?: AccessMethod
}

export enum AccessMethod {
  DOMAIN_LOCK = 'DOMAIN_LOCK',
  INVITATION_ONLY = 'INVITATION_ONLY'
}

export enum IdpType {
  GOOGLE = 'GOOGLE',
  LINKEDIN = 'LINKEDIN',
  MICROSOFT = 'MICROSOFT'
}

export type Employer = {
  authenticationStatus?: EmployerAuthenticationStatus
  authenticationSettings?: AuthenticationSettings
  id?: string
  logo?: string
  mainContact?: MainContact
  name: string
  code?: string
  numAllocatedSeats?: number
  status?: EmployerActiveStatus
  numOpenSeats?: number
  numUsedSeats?: number
}

enum EmployerActiveStatus {
  ACTIVE,
  INACTIVE
}

type MainContact = {
  email: string
  name: string
  phone: string
}

export enum EmployerAuthenticationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED'
}

export type CurrentAccount = {
  id: string
  userId: string
  email: string
  employer: Employer
  employerId: string
  organizationId: string
  user: User
  role?: string
  jobsAccess?: EmployerStaffAccountJobsAccessLevel
  ehiAccess?: EmployerStaffAccountEhiAccessLevel
  roles: string[]
}

export type EmployerStaffAccount = {
  ehiAccess: EmployerStaffAccountEhiAccessLevel
  email: string
  employer: Employer
  id: string
  isUsingSeat: boolean
  jobsAccess: EmployerStaffAccountJobsAccessLevel
  roles: string[]
  user: User
  status: EmployerStaffAccountStatus
}

export type UserProfile = {
  id: string
  email: string
  roles: string[]
  userId: string
  status: string
  fullName?: string | undefined | null
  updatedAt: string | Date
}

export type UserProfileEdge = {
  node: UserProfile
}

export type UserProfilePermissionsResponse = {
  updateUserPermission: UserProfile
}

export type Account = {
  cmsApiKey: string
  currentAccount: CurrentAccount
}

export type Organization = {
  name: string
  id: string
  code: string
  authenticationStatus: string
  status: string
  insertedAt: string | Date
  updatedAt: string | Date
}

export type OrganizationEdge = {
  node: Organization
  cursor: string
}

export type OrganizationEmployers = {
  edges: OrganizationEdge[]
}

export type OrganizationData = {
  employers: OrganizationEmployers | undefined
}

export type OrganizationsResponseType = {
  data: OrganizationData | null
}

export type EmployerLoginTypeResponse = {
  employerAuthenticationSettings: EmployerLoginType
}

export type EmployerLoginType = {
  employerId: string
  strategy: 'DOMAIN_LOCK' | 'INVITATION_ONLY' | undefined | null
  idpType: string | undefined | null
}

export type CreateEmployerResponseType = {
  id: string
  code: string
  name: string
}

export type UpdateEmployerNameResponseType = {
  id: string
  code: string
  name: string
  organizationId: string
  authenticationStatus: string
  status: string
}

export type GetEmployerResponseType = {
  id: string
  name: string
  code: string
  status: string
}
