/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { useLazyQuery, useQuery, useMutation } from '@apollo/client'
import {
  GET_ATS_JOBS,
  GET_ATS_JOB_BY_ID,
  LINKED_ACCOUNT,
  CREATE_LINKED_ACCOUNT,
  LINK_TOKEN,
  GET_ATS_SYNC_STATUS
} from 'context/AtsContext/gql'
import { AtsJobsData, AtsJobsResponse, Job, JobEdge } from 'model/ats'

// maps the jobs response to get all the nodes (Job[])
const getJobsFromResponse = (atsJobsData: AtsJobsData): Job[] => {
  const jobs: Job[] = atsJobsData.atsJobs.edges.map(
    (edge: JobEdge) => edge.node
  )
  return jobs
}

export const useAts = () => {
  // get ats job by id from BE
  const [getAtsJobById, { data: atsJob, error: atsJobError }] =
    useLazyQuery(GET_ATS_JOB_BY_ID)

  const INITIAL_JOBS_RESPONSE: AtsJobsResponse = {
    data: null
  }

  const [atsJobsData, setAtsJobsData] = useState<AtsJobsData | null>(null)
  const [jobs, setJobs] = useState<Job[]>([] as Job[])
  const [jobsIsLoading, setJobsIsLoading] = useState<boolean>(true)
  const [jobsError, setJobsError] = useState<boolean>(false)
  const [job, setJob] = useState<Job | null>(null)
  const [jobIsLoading, setJobIsLoading] = useState<boolean>(true)
  const [jobError, setJobError] = useState<boolean>(false)
  const [linkedAccount, setLinkedAccount] = useState<any>(undefined)
  const [linkToken, setLinkToken] = useState<any>(undefined)
  const [isAtsLinked, setIsAtsLinked] = useState<boolean>(false)
  const [atsLastSynced, setAtsLastSynced] = useState<string | null>(null)

  // get ats sync status from BE
  const { data: atsSyncStatusData } = useQuery(GET_ATS_SYNC_STATUS)

  // get ats jobs from BE
  const {
    data: atsJobsResponse = INITIAL_JOBS_RESPONSE,
    error: atsJobsResponseError,
    refetch: refetchAtsJobs
  } = useQuery(GET_ATS_JOBS)

  // get linked account data
  const {
    data: linkedAccountData,
    loading: linkedAccountIsLoading,
    error: linkedAccountError
  } = useQuery(LINKED_ACCOUNT, {
    variables: { category: 'ats' }
  })

  // create linked account
  const [createLinkedAccount] = useMutation(CREATE_LINKED_ACCOUNT, {
    variables: { category: 'ats' },
    // update the cache, this is required so changes are displayed immediately
    update(cache: any, { data: { linkedAccountResponse } }: any): void {
      cache.modify({
        fields: {
          linkedAccount(): any {
            return linkedAccountResponse
          }
        }
      })
    }
  })

  // get Link Token
  const [getLinkToken, { data: mergeLinkToken }] = useLazyQuery(LINK_TOKEN)

  // set linkedAccount
  useEffect(() => {
    if (linkedAccountData) {
      setLinkedAccount(linkedAccountData.linkedAccount)
    }
  }, [linkedAccountData])

  // set job by ID
  useEffect(() => {
    if (atsJob) {
      setJob(atsJob.data.atsJob)
      setJobIsLoading(false)
    }
  }, [atsJob])

  // handle job error response
  useEffect(() => {
    if (atsJobError) {
      setJobError(true)
      setJobIsLoading(false)
    }
  }, [atsJobError])

  // set jobs data
  useEffect(() => {
    if (atsJobsResponse) {
      setAtsJobsData(atsJobsResponse)
    }
  }, [atsJobsResponse])

  // set link token
  useEffect(() => {
    if (mergeLinkToken) {
      setLinkToken(mergeLinkToken)
    }
  }, [mergeLinkToken])

  // process atsJobs into Job[]
  useEffect(() => {
    if (atsJobsData && atsJobsData.atsJobs) {
      const nodeJobs: Job[] = getJobsFromResponse(atsJobsData)
      setJobs(nodeJobs)
      setJobsIsLoading(false)
    }
  }, [atsJobsData])

  // handle jobs error response
  useEffect(() => {
    if (atsJobsResponseError) {
      setJobsError(true)
      setJobsIsLoading(false)
    }
  }, [atsJobsResponseError])

  // set if ats is linked or not
  useEffect(() => {
    if (atsSyncStatusData) {
      const isLinked = !!atsSyncStatusData?.atsSyncStatus?.jobs
      setIsAtsLinked(isLinked)
      if (isLinked) {
        setAtsLastSynced(atsSyncStatusData.atsSyncStatus?.jobs?.lastSyncedDate)
      }
    }
  }, [atsSyncStatusData])

  return {
    refetchAtsJobs,
    jobs,
    jobsIsLoading,
    jobsError,
    getAtsJobById,
    job,
    jobError,
    jobIsLoading,
    linkedAccount,
    linkedAccountError,
    linkedAccountIsLoading,
    linkToken,
    getLinkToken,
    createLinkedAccount,
    isAtsLinked,
    atsLastSynced
  }
}
