import React, { Box, Typography, Button } from '@mui/material'
import { SalesCardType } from 'model/atsMock'
import AtsButton from 'components/AtsButton'

interface IProps {
  data: SalesCardType
  onClick: () => void
  isError?: boolean
}

const AtsCard = ({ data, onClick, isError }: IProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '1354px',
        borderRadius: '8px',
        border: '1px solid #E5E5E5',
        marginBottom: '50px',
        padding: '32px 52px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#fff'
      }}
    >
      {isError ? (
        <Box
          sx={{
            width: '59.2%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              lineHeight: '40px',
              fontSize: '36px',
              letterSpacing: '-0.72px',
              marginBottom: '24px',
              width: '100%',
              textAlign: 'left'
            }}
          >
            There seems to be an error retrieving your ATS data
          </Typography>

          <Typography
            sx={{
              width: '100%',
              textAlign: 'left'
            }}
          >
            Please contact us to fix this issue.
          </Typography>
          <Box sx={{ margin: '40px 0' }}>
            <Button
              sx={{
                backgroundColor: '#191919',
                border: '1px solid #191919',
                color: 'white',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: 'cccccc',
                  color: 'black',
                  border: '1px solid #191919'
                }
              }}
              onClick={onClick}
            >
              Contact Us
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: '59.2%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              lineHeight: '40px',
              fontSize: '36px',
              letterSpacing: '-0.72px',
              marginBottom: '24px',
              width: '100%',
              textAlign: 'left'
            }}
          >
            Transform your DEI strategy with <br /> inclusive analytics
          </Typography>

          <Typography
            sx={{
              width: '100%',
              textAlign: 'left'
            }}
          >
            Seamlessly integrate your ATS to proactively identify gaps and
            improve <br /> opportunity equity, without needing an analyst. We’ll
            enhance your existing <br />
            people data to better track vital workforce information, all through
            our secure platform.
          </Typography>
          <AtsButton />
          {/* <Box sx={{ margin: '40px 0' }}>
            <Button
              sx={{
                backgroundColor: '#191919',
                border: '1px solid #191919',
                color: 'white',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: 'cccccc',
                  color: 'black',
                  border: '1px solid #191919'
                }
              }}
              onClick={onClick}
            >
              Link ATS Account
            </Button>
          </Box> */}
        </Box>
      )}

      <img
        src={data.url}
        style={{ width: '40%', borderRadius: '8px' }}
        alt='sales card media'
      />
    </Box>
  )
}

export default AtsCard
