/* eslint-disable no-console */
import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { AtsContext } from 'context/AtsContext'
import LoadingIndicator from 'components/LoadingIndicator'
import AtsDownloadButton from 'components/AtsDownloadButton/AtsDownloadButton'
import Feedback from 'components/Feedback'
import JobsPage from '../JobsPage'
import SalesPage from '../SalesPage'
import ErrorPage from '../ErrorPage'

interface LandingPageProps {
  jobId: string | undefined
}

const LandingPage = ({ jobId }: LandingPageProps) => {
  const {
    jobs,
    jobsIsLoading,
    jobsError,
    isAtsLinked,
    atsLastSynced,
    linkedAccount
  } = useContext(AtsContext)

  // TODO: Replace values with actual
  const lastSyncedDate = atsLastSynced || 'not available'
  const integration = linkedAccount?.integrationName

  const handleDownload = () => {
    console.log('downloaded')
  }

  const linkMerge = () => {
    console.log('merged')
  }

  const contactUs = () => {
    console.log('contact us!')
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'relative',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      {jobsIsLoading ? (
        <LoadingIndicator />
      ) : (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            {isAtsLinked && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  marginBottom: '50px'
                }}
              >
                <Box>
                  <Typography
                    sx={{ textAlign: 'left' }}
                    variant='h1'
                    marginBottom='24px'
                  >
                    ATS Dashboard
                  </Typography>
                  <Typography
                    sx={{ paddingLeft: '3px' }}
                    variant='body2'
                    marginBottom='10px'
                  >
                    Last refreshed{' '}
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`${lastSyncedDate} `}</span>
                    from{' '}
                    <span style={{ fontWeight: 'bold' }}>{integration}</span>
                  </Typography>
                  <Box>
                    <Feedback />
                  </Box>
                </Box>
                <AtsDownloadButton onClick={handleDownload} />
              </Box>
            )}
          </Box>
          {jobsError ? (
            <Box>
              <ErrorPage onClick={contactUs} />
            </Box>
          ) : (
            <Box sx={{ width: '100%' }}>
              {isAtsLinked ? (
                <JobsPage jobs={jobs} />
              ) : (
                <SalesPage onClick={linkMerge} />
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
export default LandingPage
